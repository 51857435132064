import React from 'react';
import './Pvc.css'; // Import CSS for styling and animations

// Import all images
import p1 from './images/PVC-Fitting-1.webp';
import p2 from './images/PVC-Fitting-2.webp';
import p3 from './images/PVC-Fitting-3.webp';
import p4 from './images/PVC-Fitting-4.webp';
import p5 from './images/PVC-Fitting-5.webp';
import p6 from './images/PVC-Fitting-6.webp';
import p7 from './images/PVC-Fitting-7.webp';
import p8 from './images/PVC-Fitting-8.webp';
import p9 from './images/PVC-Fitting-9.webp';
import p10 from './images/PVC-FittingM-1.webp';
import p11 from './images/PVC-FittingM-10.webp';
import p12 from './images/PVC-FittingM-11.webp';
import p13 from './images/PVC-FittingM-12.webp';
import p14 from './images/PVC-FittingM-13.webp';
import p15 from './images/PVC-FittingM-14.webp';
import p16 from './images/PVC-FittingM-15.webp';
import p17 from './images/PVC-FittingM-15.webp';
import p18 from './images/PVC-FittingM-16.webp';
import p19 from './images/PVC-FittingM-2.webp';
import p20 from './images/PVC-FittingM-3.webp';
import p21 from './images/PVC-FittingM-4.webp';
import p22 from './images/PVC-FittingM-5.webp';
import p23 from './images/PVC-FittingM-6.webp';
import p24 from './images/PVC-FittingM-7.webp';
import p25 from './images/PVC-FittingM-8.webp';
import p26 from './images/PVC-FittingM-9.webp';

function Pvc() {
  // Array of all images
  const images = [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19, p20, p21, p22, p23, p24, p25, p26];

  return (
    <div>
      {/* Header Section */}
      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">PVC Fittings</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Products</a></li>
              <li className="breadcrumb-item text-white active" aria-current="page">PVC Fittings</li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Image Gallery Section */}
      <div className="container">
        <div className="row">
          {images.map((image, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={index}>
              <div className="image-container">
                <img src={image} alt={`PVC Fitting ${index + 1}`} className="img-fluid animated-image" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Pvc;
