import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Footer from './Footer';
import Header from './Header';
import Services from './Services';
import Product from './Product';
import Contact from './Contact';
import About from './About';
import Blow from './Blow'
import Pvc from './Pvc';
import Electrical from './Electrical';
import Agri from './Agri';
import Vdo from './vdo'
import Tech from './Tech';

function App() {
  return (
    <Router>
     <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="service" element={<Services />} />
        <Route path="product" element={<Product />} />
        <Route path="contact" element={<Contact />} />
        <Route path="blow" element={<Blow />} />
        <Route path="pvc" element={<Pvc />} />
        <Route path="electric" element={<Electrical />} />
        <Route path="agri" element={<Agri/>} />
        <Route path="vdo" element={<Vdo/>} />
        <Route path="tech" element={<Tech/>} />

        
      </Routes>
      <Footer /> 
    </Router>
  );
}

export default App;
