import React from 'react';
import b1 from './images/Blow-1.webp';
import b2 from './images/Blow-2.webp';
import b3 from './images/Blow-3.webp';
import b4 from './images/Blow-4.webp';
import b5 from './images/BlowM-2 (1).webp';
import b6 from './images/BlowM-1.webp';
import './Blow.css'; // Import CSS for additional styling and animations

function Blow() {
  return (
    <div>
      {/* Header Section */}
      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">Blow Moulds</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a className="text-white" href="#">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <a className="text-white" href="#">
                  Pages
                </a>
              </li>
              <li className="breadcrumb-item text-white active" aria-current="page">
                Blow Moulds
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Image Gallery Section */}
      <div className="container">
        <div className="row">
          {[b1, b2, b3, b4, b5, b6].map((image, index) => (
            <div className="col-md-4 col-sm-6 mb-4" key={index}>
              <div className="image-container">
                <img src={image} alt={`Blow mould ${index + 1}`} className="img-fluid animated-image" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blow;
