import React from 'react';
import './Tech.css';
import cn from './images/cn.jpeg';
import c from './images/c.jpg';

function Tech() {
  return (
    <div>
      {/* Header Section */}
      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">Technical Information</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
              <li className="breadcrumb-item text-white active" aria-current="page">Technical Information</li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Technical Information Section */}
      <div className="container my-5">
        <h2 className="text-center mb-5">Our Expertise and Technical Information</h2>

        {/* Manufacturing Process Section */}
        <div className="row align-items-center mb-5">
          <div className="col-md-6">
            <img src={cn} alt="Manufacturing Process" className="img-fluid rounded" />
          </div>
          <div className="col-md-6">
            <h3>State-of-the-Art Manufacturing</h3>
            <p>
              Kastury Industries utilizes advanced molding techniques and cutting-edge machinery to ensure the highest
              quality in our PVC pipes, electrical fittings, and agricultural products. Our facilities are ISO-certified,
              and we adhere to rigorous quality standards for each product line.
            </p>
          </div>
        </div>

        {/* Quality Assurance Section */}
        <div className="row align-items-center mb-5">
          <div className="col-md-6 order-md-2">
            <img src={c} alt="Quality Assurance" className="img-fluid rounded" />
          </div>
          <div className="col-md-6 order-md-1">
            <h3>Quality Assurance</h3>
            <p>
              Our quality assurance team performs extensive testing to meet industry standards for strength, durability,
              and safety. We conduct tests for pressure, impact resistance, and UV stability, ensuring that our products
              are suitable for various applications.
            </p>
          </div>
        </div>

        {/* Product Specifications Section */}
        <h3 className="text-center mb-4">Product Specifications</h3>
        <div className="row text-center">
          <div className="col-md-4 mb-4">
            <div className="spec-card p-4">
              <h4>PVC Pipes</h4>
              <p>Material: High-grade PVC</p>
              <p>Diameter Range: 20mm - 315mm</p>
              <p>Pressure Ratings: Up to PN16</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="spec-card p-4">
              <h4>Electrical Fittings</h4>
              <p>Material: Non-corrosive, flame-retardant</p>
              <p>Voltage Rating: Up to 600V</p>
              <p>Certifications: CE, UL compliant</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="spec-card p-4">
              <h4>Agricultural Fittings</h4>
              <p>Material: High-density PVC</p>
              <p>Temperature Range: -10°C to 60°C</p>
              <p>UV Resistant: Yes</p>
            </div>
          </div>
        </div>

        {/* Machine Specifications */}
        <h3 className="text-center mb-4">Machine Specifications</h3>
        <div className="row text-center machine-specs">
          <div className="col-md-6 mb-4">
            <div className="spec-card p-4">
              <h4>EDM Machines</h4>
              <ul>
                <li>500 x 450 x 500 - Qty: 1</li>
                <li>450 x 350 x 350 - Qty: 1</li>
                <li>650 x 350 x 450 - Qty: 1</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="spec-card p-4">
              <h4>CNC Pentograph</h4>
              <p>Laser cut slot: 0.8 mm (width) x 600 mm</p>
              <p>Quantity: 1</p>
            </div>
          </div>
        </div>

        <h3 className="text-center mb-4">Tool Room Facilities</h3>
        <div className="row text-center tool-room">
          <div className="col-md-4 mb-4">
            <div className="spec-card p-4">
              <h4>Hydraulic Surface Grinder</h4>
              <p>General 850 x 550 x 400 - Qty: 1</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="spec-card p-4">
              <h4>Vertical Milling</h4>
              <p>Bed length: 1200 mm, Qty: 2</p>
              <p>Size: 600 mm x 500 mm x 400 mm</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="spec-card p-4">
              <h4>Lathe Machines</h4>
              <ul>
                <li>Rajkot 16ft, Center height: 24"</li>
                <li>HMT 8ft, Center height: 10"</li>
              </ul>
            </div>
          </div>
        </div>
        
        {/* CNC Tool Room Facilities */}
        <h3 className="text-center mb-4">CNC Tool Room Facilities</h3>
        <div className="row text-center cnc-facilities">
          <div className="col-md-6 mb-4">
            <div className="spec-card p-4">
              <h4>CNC Lathe M/C ACE Classic LT 20</h4>
              <p>Bed capacity: 500 x 700 mm</p>
              <p>Accuracy: 0.005 mm</p>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="spec-card p-4">
              <h4>CNC Wire Cut (E.D.M.)</h4>
              <p>Table Size: 500 x 360 mm</p>
              <p>XY Travel: 200 x 250 mm</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tech;
