import React from 'react';
import { FaPrint, FaTools, FaCogs, FaMugHot, FaDolly, FaCube, FaBox, FaStar, FaWrench, FaWater, FaPencilRuler, FaBuilding } from 'react-icons/fa';

const iconColors = [
    '#e74c3c', // Red
    '#3498db', // Blue
    '#2ecc71', // Green
    '#f39c12', // Orange
    '#9b59b6', // Purple
    '#e67e22', // Pumpkin
    '#1abc9c', // Turquoise
    '#34495e', // Dark Blue
    '#f1c40f', // Yellow
    '#2c3e50', // Dark Gray
    '#7f8c8d', // Gray
    '#c0392b', // Strong Red
];

function Services() {
    return (
        <div>
            {/* Page Header Start */}
            <div className="container-fluid page-header py-5 mb-5">
                <div className="container py-5">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Services</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">Services</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Page Header End */}

            {/* Service Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center">
                        <div className="bg-primary mb-3 mx-auto" style={{ width: '60px', height: '2px' }}></div>
                        <h1 className="display-5 mb-5">Our Services</h1>
                    </div>
                    <div className="row g-0 service-row">
                        {/* Each service item */}
                        {[
                            { icon: FaPrint, title: "Vacuum Casting", description: "High-quality vacuum casting and silicone molding services for prototypes and small batch productions.", color: iconColors[0] },
                            { icon: FaWrench, title: "Various Types of Pipe Fitting Moulds", description: "Custom molds for various pipe fittings including UPVC, CPVC, and PVC types.", color: iconColors[1] },
                            { icon: FaWater, title: "UPVC Plumbing Fitting Mould", description: "High-quality molds for UPVC plumbing fittings, ensuring durability and precision.", color: iconColors[2] },
                            { icon: FaWater, title: "CPVC Plumbing Fitting Mould", description: "Precision molds for CPVC plumbing fittings to meet high industry standards.", color: iconColors[3] },
                            { icon: FaWater, title: "PVC Drainages/Sewage Fitting Mould", description: "Durable molds for PVC drainage and sewage fittings, suitable for various applications.", color: iconColors[4] },
                            { icon: FaWater, title: "PVC Water Supply Fitting Mould", description: "Custom molds for PVC water supply fittings to ensure reliability and performance.", color: iconColors[5] },
                            { icon: FaPrint, title: "3D Printing", description: "Advanced 3D printing services for accurate, detailed models using various technologies like SLA, SLS, and FDM.", color: iconColors[6] },
                            { icon: FaCogs, title: "CNC Machining", description: "Custom CNC machined prototypes available in plastics, metals, wood, foam, and more. High precision and quality.", color: iconColors[7] },
                            { icon: FaMugHot, title: "Soft Tooling/Injection Molding", description: "Cost-effective injection molding and soft tooling solutions for low-volume production and prototyping.", color: iconColors[8] },
                            { icon: FaDolly, title: "DMLS (Direct Metal Laser Sintering)", description: "Advanced metal additive manufacturing using DMLS technology for functional metal prototypes.", color: iconColors[9] },
                            { icon: FaCube, title: "FDM (Fused Deposition Modeling)", description: "FDM technology for creating detailed, functional 3D printed models in a wide range of materials.", color: iconColors[10] },
                            { icon: FaBuilding, title: "PVC Rainwater System Mould", description: "Molds for PVC rainwater systems, designed for efficient collection and drainage.", color: iconColors[11] },
                        ].map((service, index) => (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 wow fadeIn" data-wow-delay={`${index * 0.2}s`} key={index}>
                                <div className="service-item border h-100 p-5">
                                    <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <service.icon size={32} color={service.color} />
                                    </div>
                                    <h4 className="mb-3">{service.title}</h4>
                                    <p className="mb-4">{service.description}</p>
                                    <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* Service End */}
        </div>
    );
}

export default Services;
