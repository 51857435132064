import React from 'react';
import about from './images/cnc2.jpeg';
import './About.css'

function About() {
    return (
        <div>
            {/* Page Header Start */}
            <div className="container-fluid page-header py-5 mb-5">
                <div className="container py-5">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">About Us</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Page Header End */}

            <div className="container-fluid bg-white py-5">
    <div className="container">
        <h2 className="display-5 mb-4">Our Journey</h2>
        <ul className="timeline">
            <li>
                <span className="year">2000</span>
                <p>Started with a 2000 sq.ft rented facility and 3 employees. Developed first mold for Lumax (Mirror back Cover).</p>
            </li>
            <li>
                <span className="year">2003</span>
                <p>Expanded to a new 5000 sq.ft facility at M-34 with 25+ employees and a design team using AutoCAD. Completed 35 molds with successful trials.</p>
            </li>
            <li>
                <span className="year">2005</span>
                <p>Acquired DELCAM Power Mill CAM software and added CNC milling machines. Expanded CNC unit to a 7000 sq.ft facility at M-7. Increased mold delivery to 70 over two years.</p>
            </li>
            <li>
                <span className="year">2010</span>
                <p>Established a new company for hard chrome plating, diamond polishing, and chemical etching in a 12,000 sq.ft facility at M-61, with 45+ employees.</p>
            </li>
            <li>
                <span className="year">2014</span>
                <p>Opened a new molding and press machine facility at M-60, expanding to 8000 sq.ft with 60+ employees.</p>
            </li>
        </ul>
    </div>
</div>

            {/* About Start */}
            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <div className="container about px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-6 ps-lg-0" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute img-fluid w-100 h-100" src={about} style={{ objectFit: 'cover' }} alt="About Us" />
                            </div>
                        </div>
                        <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 pe-lg-0">
                                <div className="bg-primary mb-3" style={{ width: '60px', height: '2px' }}></div>
                                <h1 className="display-5 mb-4">About Us</h1>
                                <p className="mb-4 pb-2" style={{ textAlign: 'justify' }}>
                                    Kasturi Group is a high-quality mould making facility, fully integrated with design development and manufacturing of injection and blow moulds. We also manufacture moulded components for agricultural appliances and other industries. Our deep industry knowledge and extensive experience allow us to genuinely partner with our customers, focusing on their satisfaction and progress.
                                </p>
                                <div className="row g-4 mb-4 pb-3">
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                        <div className="d-flex align-items-center">
                                            <div className="btn-square bg-white rounded-circle" style={{ width: '64px', height: '64px' }}>
                                                <img className="img-fluid" src="img/icon/icon-1.png" alt="Icon" />
                                            </div>
                                            <div className="ms-4">
                                                <h2 className="mb-1" data-toggle="counter-up">45 Years</h2>
                                                <p className="fw-medium text-primary mb-0">Experience</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                        <div className="d-flex align-items-center">
                                            <div className="btn-square bg-white rounded-circle" style={{ width: '64px', height: '64px' }}>
                                                <img className="img-fluid" src="img/icon/icon-5.png" alt="Icon" />
                                            </div>
                                            <div className="ms-4">
                                                <h2 className="mb-1" data-toggle="counter-up">200 KM</h2>
                                                <p className="fw-medium text-primary mb-0">Transportation Radius</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="contact" className="btn btn-primary rounded-pill py-3 px-5">Explore More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About End */}

            {/* Milestones Start */}
           

            {/* Milestones End */}
            
            {/* Footer and other components here */}
        </div>
    );
}

export default About;
