import React from 'react';
import './Electrical.css'; // Import CSS for styling and animations

// Importing images
import e1 from './images/Electrick-1.webp';
import e2 from './images/Electrick-2.webp';
import e3 from './images/Electrick-3.webp';
import e4 from './images/Electrick-4.webp';
import e5 from './images/Electrick-5.webp';

function Electrical() {
  // Array of images to simplify gallery display
  const images = [e1, e2, e3, e4, e5];

  return (
    <div>
      {/* Header Section */}
      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">Electrical Products</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
              <li className="breadcrumb-item text-white active" aria-current="page">Electrical</li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Image Gallery Section */}
      <div className="container">
        <div className="row">
          {images.map((image, index) => (
            <div className="col-md-4 col-sm-6 mb-4" key={index}>
              <div className="image-container">
                <img src={image} alt={`Electrical product ${index + 1}`} className="img-fluid animated-image" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Electrical;
