import React from 'react'
import About from './images/kasta.jpg'
import why from './images/kastu.jpeg'
import './Home.css'
import free from './images/ww.jpeg'
import ppp from './images/m1.jpg'
import pipe1 from './images/kast1.jpg'
import pipe2 from './images/kast2.jpg'
import pipe3 from './images/kast3.jpg'
import pipe4 from './images/kast4.jpg'
import pipe5 from './images/kast5.jpg'
import pipe6 from './images/kast6.jpg'
import pipe7 from './images/kast7.jpg'
import pipe8 from './images/kast8.jpeg'
import pipe9 from './images/swr.jpeg'
import { FaPrint, FaTools, FaCogs, FaMugHot, FaDolly, FaCube, FaBox, FaStar, FaWrench, FaWater, FaPencilRuler, FaBuilding } from 'react-icons/fa';

const iconColors = [
    '#e74c3c', // Red
    '#3498db', // Blue
    '#2ecc71', // Green
    '#f39c12', // Orange
    '#9b59b6', // Purple
    '#e67e22', // Pumpkin
    '#1abc9c', // Turquoise
    '#34495e', // Dark Blue
    '#f1c40f', // Yellow
    '#2c3e50', // Dark Gray
    '#7f8c8d', // Gray
    '#c0392b', // Strong Red
];


function Home() {
  return (
    <div>
   <div 
    className="container py-5"
    style={{
        backgroundImage: `url(${ppp})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        position: 'relative',
        width: '100vw', 
        height: '60vh', 
        overflow: 'hidden' 
    }}
>
    <div 
        className="overlay"
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            zIndex: 1
        }}
    ></div>
    <div 
        className="content"
        style={{
            position: 'relative',
            zIndex: 2,
            textAlign: 'center',
            paddingTop: '20px' 
        }}
    >
        <h1 className="display-3 mb-3 animated slideInDown" style={{color: 'white',marginTop:'10vh'}}>Welcome to Kasturi Engineering</h1>      
        <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center" >
                <li className="breadcrumb-item" ><a className="text-white" href="#">Home</a></li>
            </ol>
        </nav>
    </div>
</div>

             
   
<div className="container-xxl py-5">
    <div className="container">
        <div className="row g-4">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                <div className="h-100 bg-dark p-4 p-xl-5">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <div className="btn-square rounded-circle" style={{width: '64px', height: '64px', background: '#000000'}}>
                            <img className="img-fluid" src="img/icon/icon-3.png" alt="Icon"/>
                        </div>
                        <h1 className="display-1 mb-0" style={{color: 'white'}}>01</h1>
                    </div>
                    <h5 className="text-white">Quality of Products</h5>
                    <hr className="w-25"/>
                    <span>"Our Survival Is In Quality." These products are widely appreciated for their durability and longer functional life. Made with high-quality raw materials and components, our products meet the highest standards. Our customization facility enables us to meet urgent demands and win the trust of our customers. They are used in various engineering and related industries.</span>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <div className="h-100 bg-dark p-4 p-xl-5">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <div className="btn-square rounded-circle" style={{width: '64px', height: '64px', background: '#000000'}}>
                            <img className="img-fluid" src="img/icon/icon-4.png" alt="Icon"/>
                        </div>
                        <h1 className="display-1 mb-0" style={{color: 'white'}}>02</h1>
                    </div>
                    <h5 className="text-white">Professional Team</h5>
                    <hr className="w-25"/>
                    <span>We have a team of experts ready to help clients with the best possible solution for their mold requirements. From the first stage of mold design to the final product, our in-house team manages the entire project to ensure perfection.</span>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <div className="h-100 bg-dark p-4 p-xl-5">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <div className="btn-square rounded-circle" style={{width: '64px', height: '64px', background: '#000000'}}>
                            <img className="img-fluid" src="img/icon/icon-2.png" alt="Icon"/>
                        </div>
                        <h1 className="display-1 mb-0" style={{color: 'white'}}>03</h1>
                    </div>
                    <h5 className="text-white">Affordable Price</h5>
                    <hr className="w-25"/>
                    <span>At Kasturi, we set prices that achieve positive results without compromising on quality. Our pricing strategy is not just about lowering prices but understanding consumer perceptions. We aim to offer value to customers while ensuring price points that work for everyone.</span>
                </div>
            </div>
        </div>
    </div>
</div>





<div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
    <div className="container feature px-lg-0">
        <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.5s">
                <div className="p-lg-5 ps-lg-0">
                    <div className="bg-primary mb-3" style={{ width: '60px', height: '2px' }}></div>
                    <h1 className="display-5 mb-5">Why Choose Us</h1>
                    <p className="mb-4 pb-2">
                        We provide top-quality plumbing pipes and services tailored to your needs. With a focus on reliability, efficiency, and customer satisfaction, we ensure that your plumbing system works flawlessly.
                    </p>

                    <div className="row g-4">
                        {/* Point 1 */}
                        <div className="col-6">
                            <div className="d-flex align-items-center">
                                <div className="btn-square bg-white rounded-circle" style={{ width: '64px', height: '64px' }}>
                                    <img className="img-fluid" src="img/icon/icon-1.png" alt="Icon" />
                                </div>
                                <div className="ms-4">
                                    <h5 className="mb-0">45 Years Experience</h5>
                                    <p className="text-primary mb-2">Tool Manufacturing</p>
                                </div>
                            </div>
                        </div>
                        {/* Point 2 */}
                        <div className="col-6">
                            <div className="d-flex align-items-center">
                                <div className="btn-square bg-white rounded-circle" style={{ width: '64px', height: '64px' }}>
                                    <img className="img-fluid" src="img/icon/icon-2.png" alt="Icon" />
                                </div>
                                <div className="ms-4">
                                    <h5 className="mb-0">Raw Material Availability</h5>
                                    <p className="text-primary mb-2">5 CNC Machines</p>
                                </div>
                            </div>
                        </div>
                        {/* Point 3 */}
                        <div className="col-6">
                            <div className="d-flex align-items-center">
                                <div className="btn-square bg-white rounded-circle" style={{ width: '64px', height: '64px' }}>
                                    <img className="img-fluid" src="img/icon/icon-3.png" alt="Icon" />
                                </div>
                                <div className="ms-4">
                                    <h5 className="mb-0">All Work Under One Roof 50+ Skilled Workers</h5>
                                    <p className="text-primary mb-2">No Outsourcing Needed</p>
                                </div>
                            </div>
                        </div>
                        {/* Point 4 */}
                        <div className="col-6">
                            <div className="d-flex align-items-center">
                                <div className="btn-square bg-white rounded-circle" style={{ width: '64px', height: '64px' }}>
                                    <img className="img-fluid" src="img/icon/icon-4.png" alt="Icon" />
                                </div>
                                <div className="ms-4">
                                    <h5 className="mb-0">Design & Analysis Support</h5>
                                </div>
                            </div>
                        </div>
                        {/* Point 5 */}
                        <div className="col-6">
                            <div className="d-flex align-items-center">
                                <div className="btn-square bg-white rounded-circle" style={{ width: '64px', height: '64px' }}>
                                    <img className="img-fluid" src="img/icon/icon-5.png" alt="Icon" />
                                </div>
                                <div className="ms-4">
                                    <h5 className="mb-0">Financial Stability</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 className="mt-5">Why Kasturi Group?</h2>
                    <ul>
                        <li>Low Cost Tooling</li>
                        <li>Tooling Manufacturing and Production Capacity</li>
                        <li>Support for Industrial Design</li>
                        <li>Own Transportation within a 200 KM Radius</li>
                    </ul>
                </div>
            </div>

            <div className="col-lg-6 pe-lg-0" style={{ minHeight: '400px' }}>
                <div className="position-relative h-100">
                    <img className="position-absolute img-fluid w-100 h-100" src={why} style={{ objectFit: 'cover' }} alt="" />
                </div>
            </div>
        </div>
    </div>
</div>




<div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <div className="bg-primary mb-3 mx-auto" style={{ width: '60px', height: '2px' }}></div>
            <h1 className="display-5 mb-5">Our Product</h1>
          </div>
          <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.3s">
            <div className="col-12 text-center">
              <ul className="list-inline mb-5" id="portfolio-flters">
                <li className="mx-2 active" data-filter="*">All</li>
              
              </ul>
            </div>
          </div>
          <div className="row g-4 portfolio-container">
            
            {/* PVC Pipes */}
            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe1} alt="" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2"> Plastic Drum Mould</p>
                
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                 
                  <h5 className="lh-base mb-3">Ideal for residential water systems and drainage applications.</h5>
                  <div className="d-flex justify-content-center">
                   
                  
                  </div>
                </div>
              </div>
            </div>

            {/* Copper Pipes */}
            <div className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.3s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe3} alt="Copper Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Customized Plastic Injection Mould</p>
                
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                
                  <h5 className="lh-base mb-3">Corrosion-resistant and ideal for high-pressure Moulds systems.</h5>
                  <div className="d-flex justify-content-center">
                   
                  </div>
                </div>
              </div>
            </div>

            {/* Galvanized Pipes */}
            <div className="col-lg-4 col-md-6 portfolio-item third wow fadeInUp" data-wow-delay="0.5s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe2} alt="Galvanized Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Plastic Dustbin Moulding Die</p>
                
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                 
                  <h5 className="lh-base mb-3">Perfect for outdoor plumbing and irrigation systems.</h5>
                  <div className="d-flex justify-content-center">
                    
                 
                  </div>
                </div>
              </div>
            </div>

            {/* PEX Pipes */}
            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe4} alt="PEX Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Plastic Rotational Mould</p>
                 
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                
                  <h5 className="lh-base mb-3">A plastic rotational mould is used to create hollow plastic products by rotating and heating the mould.</h5>
                  <div className="d-flex justify-content-center">
                   
                  </div>
                </div>
              </div>
            </div>

            {/* CPVC Pipes */}
            <div className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.3s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe5} alt="CPVC Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Plastic Bucket Mould</p>
                 
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                
                  <h5 className="lh-base mb-3">A plastic bucket mould is a tool for shaping plastic buckets through injection molding.</h5>
                  <div className="d-flex justify-content-center">
                   
                  </div>
                </div>
              </div>
            </div>

            {/* Stainless Steel Pipes */}
            <div className="col-lg-4 col-md-6 portfolio-item third wow fadeInUp" data-wow-delay="0.5s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe6} alt="Stainless Steel Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Plastic Square Container Mould</p>
                 
                </div>
                <div className="portfolio-text text-center bg-white p-4">
               
                  <h5 className="lh-base mb-3">A plastic square container mould is used for shaping square plastic containers through injection molding.</h5>
                  <div className="d-flex justify-content-center">
                    
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe7} alt="PEX Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Plastic Mould</p>
                 
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                
                  <h5 className="lh-base mb-3">A plastic  mould is used to create hollow plastic products by  heating the mould.</h5>
                  <div className="d-flex justify-content-center">
                   
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe8} alt="PEX Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">swr fitting</p>
                 
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                
                  <h5 className="lh-base mb-3">A SWR fitting mould is used for manufacturing soil, waste, and rainwater (SWR) pipe fittings through injection molding</h5>
                  <div className="d-flex justify-content-center">
                   
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe9} alt="PEX Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">swr fitting </p>
                 
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                
                  <h5 className="lh-base mb-3">A SWR fitting mould is used for manufacturing soil, waste, and rainwater (SWR) pipe fittings through injection molding.</h5>
                  <div className="d-flex justify-content-center">
                   
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    









    
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
    <div className="container quote px-lg-0">
        <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: '400px' }}>
                <div className="position-relative h-100">
                    <img
                        className="position-absolute img-fluid w-100 h-100"
                        src={free}
                        style={{ objectFit: 'cover' }}
                        alt=""
                    />
                </div>
            </div>
            <div className="col-lg-6 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
                <div className="p-lg-5 pe-lg-0">
                    <div className="bg-primary mb-3" style={{ width: '60px', height: '2px' }}></div>
                    <h1 className="display-5 mb-5">Free Quote</h1>
                    <p className="mb-4 pb-2">
                        Get a free quote for our top-notch plumbing pipe services. Whether you need new installations, maintenance, or emergency repairs, fill out the form below to receive a detailed quote tailored to your needs.
                    </p>
                    <form>
                        <div className="row g-3">
                            <div className="col-12 col-sm-6">
                                <input
                                    type="text"
                                    className="form-control border-0"
                                    placeholder="Your Name"
                                    style={{ height: '55px' }}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <input
                                    type="email"
                                    className="form-control border-0"
                                    placeholder="Your Email"
                                    style={{ height: '55px' }}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <input
                                    type="text"
                                    className="form-control border-0"
                                    placeholder="Your Mobile"
                                    style={{ height: '55px' }}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <select className="form-select border-0" style={{ height: '55px' }}>
                                    <option selected>Select A Service</option>
                                    <option value="1">Plastic Moulds</option>
                                    <option value="2">Household Moulds</option>
                                    <option value="3">Plastic Food Container Moulds</option>
                                    <option value="4">Industrial Mould</option>
                                </select>
                            </div>
                            <div className="col-12">
                                <textarea
                                    className="form-control border-0"
                                    placeholder="Special Note"
                                ></textarea>
                            </div>
                            <div className="col-12">
                            <button
    className="btn btn-primary w-100 py-3"
    type="button"
    onClick={() => window.open('https://wa.me/9422207846?text=I%20would%20like%20a%20free%20quote%20for%20your%20services.', '_blank')}
>
    Get A Free Quote
</button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>





</div>

)
}

export default Home